<template>
  <div class="packages">
    <div class="three-btns-one container mb-5">
      <button @click="$router.push('/')">{{ $t("Main") }} /</button>
      <button>{{ $t("Packages") }} /</button>

      <button>{{ $getByLang(item.name) }}</button>
    </div>

    <div class="mt-5 container">
      <div class="row">
        <div class="col-md-6 pr-2">
          <div
            id="carouselExampleInterval"
            class="carousel slide p-1"
            data-bs-ride="carousel"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                v-for="(itm, index) of item.packageImages"
                :key="index"
                data-bs-target="#carouselExampleInterval"
                :data-bs-slide-to="index"
                :class="index == 0 ? 'active' : ''"
                :aria-label="itm._id"
                aria-current="true"
              ></button>
            </div>

            <div class="carousel-inner carousel-inner-one ">
              <div
                v-for="(itm, index) of item.packageImages"
                :key="index"
                class="carousel-item"
                :class="index == 0 ? 'active' : ''"
                data-bs-interval="10000"
              >
                <img
                  :src="$baseUploadURL + itm.name"
                  class="d-block"
                  :alt="item.name"
                />
              </div>
            </div>
            <button
              class="carousel-control-prev custm-btn"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="prev"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
              <span class="visually-hidden">{{ $t("Previous") }}</span>
            </button>
            <button
              class="carousel-control-next custm-btn"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="next"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
              <span class="visually-hidden">{{ $t("Next") }}</span>
            </button>
            <div>
              <div class="sold">
                <!-- <div class="icon position-absolute">
                  <img
                    src="../../assets/images/like.svg"
                    class="like"
                    :alt="$getByLang(item.name)"
                  />
                </div> -->
                <span class="position-absolute"
                  >{{ item.itemsCount }} {{ $t("sold") }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="details p-lg-5">
            <div class="chair">
              <h3>{{ $t("bouquet of furniture") }}</h3>

              <div class="elmintNum">
                <span
                  style="float: inherit"
                  v-for="(itm, index) of item.selectedItems"
                  :key="index"
                >
                  {{ $getByLang(itm.itemId.name) }},
                </span>
              </div>
            </div>
            <span class="price">{{ item.price }} {{ $t("SAR") }}</span>
            <h5>{{ $t("details") }}</h5>
            <p class="para-details">
              {{ item.notes }}
            </p>

            <div v-if="item.vendorsId && item.vendorsId.showInDetails" class="product-details">
              <div class="content">
                <b-icon icon="patch-check" class="star"></b-icon>
                <span>{{ $t("the shop") }}</span>
                <router-link
                  :to="'/shops/' + item.vendorsId._id"
                  class="d-block"
                >
                  {{ item.vendorsId?item.vendorsId.name:"" }}
                </router-link>
              </div>
              <div class="content">
                <b-icon icon="bounding-box" class="star"></b-icon>
                <span>{{ $t("Dimensions") }}</span>
                <p>٥٥D X 55W X 100H CM</p>
              </div>
              <div class="content">
                <b-icon icon="stack" class="star"></b-icon>
                <span>{{ $t("made of") }} </span>
                <p>
                  {{ $getByLang(item.materialsId.name) }}
                </p>
              </div>
            </div>
            <div class="row quantity">
              <div class="col-md-6">
                <div class="total1">
                  <span class="d-inline-block mr-2">{{ $t("Quantity") }}:</span>
                  <button class="plus-btn" @click="body.quantity++">
                    <b-icon icon="plus-lg" class="plus"></b-icon>
                  </button>
                  <span class="mx-2">{{ body.quantity }}</span>
                  <button
                    class="dash-btn"
                    @click="body.quantity > 1 ? body.quantity-- : ''"
                  >
                    <b-icon icon="dash-lg" class="dash"></b-icon>
                  </button>
                </div>
              </div>
              <div class="col-md-6">
                <div class="total2">
                  <span> {{ $t("Total") }}:</span>
                  <span class="num"
                    >{{ item.price * body.quantity }} {{ $t("SAR") }}</span
                  >
                </div>
              </div>
            </div>
            <div class="add-to-card">
              <button @click="addToCart()"  id="AddToCart">
                <b-icon icon="cart3" class=""></b-icon>
                {{ $t("Add to cart") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {
        name: { ar: null, en: null },
        selectedItems: [
          {
            itemId: {
              name: {
                ar: "بوف بي في سي وفوم احمر",
                en: "بوف بي في سي وفوم احمر"
              }
            },
            colorId: {
              _id: null,
              name: null,
              value: null
            },
            sizeId: {
              name: { ar: null, en: null },
              _id: null,
              width: 0,
              height: 0,
              depth: 0
            }
          }
        ],
        packageImages: [],
        price: 0,
        itemsCount: 1,
        notes: null,
        materialsId: {
          name: { ar: null, en: null }
        },
        vendorsId: {
          _id: null,
          name: null
        }
      },
      body: {
        quantity: 1
      }
    };
  },
  methods: {
    addToCart() {
      if (localStorage.homeHere) {
        this.$http
          .post("cart/addPackage", {
            packageId: this.id,
            quantity: this.body.quantity,
            price: this.item.price
          })
          .then(
            (res) => {
              this.$eventHub.$emit("updateCart", {});

              this.$swal({
                title: this.$t("Added Successfully"),
                icon: "success",
                timer: 3000,
                showConfirmButton: false
              });
            },
            (err) => {
              this.$toast.add({
                severity: "error",
                summary: this.$t("Error"),
                detail: err.response.data.message,
                life: 3000
              });
            }
          );
      } else {
        this.$router.push("/login");
      }
    }
  },
  created() {
    this.id = this.$route.params.id;
    this.$http.get("packages/" + this.id).then((res) => {
      this.item = res.data;
      
    });
  }
};
</script>

<style></style>
